import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body,
  html,
  a {
    font-family: 'Ubuntu', sans-serif;
  }

  body {
    margin:0;
    padding:0;
    border: 0;
    outline: 0;
    background: #fff;
    overflow-x: hidden;
    box-sizing: border-box;
    --defaultColor: #2878f9;
  }

  a:hover {
    color: #000;
  }

  *:focus {
    outline: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    pointer-events: all;
  }
`;

export default GlobalStyles;
