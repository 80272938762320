import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import GlobalStyles from '../styles/globalStyles';

const Routes: React.FunctionComponent = () => {
  return (
    <Fragment>
      <GlobalStyles />
      <BrowserRouter>
        <Switch>
          <Route exact path="/:RoomName" component={() => <Home />} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default Routes;
