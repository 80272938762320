import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import RecordingItem from '../../components/RecordingItem';
import { getRoomRecordings } from '../../services';
import { IRecording } from '../../types/Room';

import * as S from './styles';
import { useParams } from 'react-router-dom';

interface IParams {
  RoomName: string;
}

const Home: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<IRecording[]>([]);
  const { RoomName } = useParams<IParams>();

  useEffect(() => {
    setRecordings([]);
    setIsLoading(true);
    getRoomRecordings(RoomName)
      .then((data) => {
        setRecordings(data.data);
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  }, [RoomName]);

  return (
    <Container margin={10} padding={20}>
      <Header />
      <S.Content>
        {isLoading ? (
          <S.SpinWrapper>
            <Spin size="large" spinning={isLoading} />
          </S.SpinWrapper>
        ) : (
          <S.RecordingsList>
            {recordings.length > 0 &&
              recordings.map((item, index) => {
                return (
                  <RecordingItem
                    key={item.id}
                    room_name={item.room_name}
                    id={item.id}
                    start_ts={item.start_ts}
                    duration={item.duration}
                    s3key={item.s3key}
                    max_participants={item.max_participants}
                    status={item.status}
                    share_token={item.share_token}
                    tracks={item.tracks}
                  />
                );
              })}
            {RoomName.length === 0 && <h2>Selecione techlead acima</h2>}
            {recordings.length === 0 && <h2>Nenhuma gravação disponível!</h2>}
          </S.RecordingsList>
        )}
      </S.Content>
    </Container>
  );
};

export default Home;
