import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.header`
  padding: 1rem 0.5rem;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  padding-top: 0rem;
`;

export const Container = styled.div`
  position: relative;
  padding: 0 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    width: 180px;
    height: 50px;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const H1 = styled.h1`
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin: 0.25rem 0rem;

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 0rem;
  }
`;

export const Span = styled.span`
  cursor: pointer;
`;
