export const formattingDate = (unixTimestamp: number): string => {
  const date = new Date(unixTimestamp * 1000);

  return date.toLocaleDateString('pt-br');
};

export const formattingStartTime = (unixTimestamp: number): string => {
  const time = new Date(unixTimestamp * 1000).toLocaleTimeString('pt-br');

  return time;
};
