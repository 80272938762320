import React, { FunctionComponent } from 'react';
import * as S from './styles';
import { IContainer } from './types';

const Container: FunctionComponent<IContainer> = ({
  margin,
  padding,
  children,
}) => {
  return (
    <S.Container margin={margin} padding={padding} data-testid="Container">
      {children}
    </S.Container>
  );
};

export default Container;
