import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

const Header: React.FunctionComponent = () => {
  return (
    <S.Header>
      <S.Container>
        <Link to="/">
          <img src="/assets/img/devpass-logo.png" />
        </Link>
      </S.Container>
    </S.Header>
  );
};

export default Header;
