import styled from 'styled-components';

export const RecordingItem = styled.li`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 10px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Infos = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  > a,
  button {
    padding: 8px;
    background: #fff;
    color: var(--defaultColor);
    border-radius: 4px;
    border: 1px solid var(--defaultColor);
    > span {
      margin-right: 5px;
    }
  }
`;
