import {
  IRoomsResponse,
  IRecordingsResponse,
  IAccessLinkResponse,
} from './../types/Room/index';
import api from './api';

export const getAllRooms = async (): Promise<IRoomsResponse> => {
  const response = await api.get('/rooms');
  return response.data;
};

export const getRoomRecordings = async (
  roomName: string,
): Promise<IRecordingsResponse> => {
  const response = await api.get('/recordings', {
    params: { room_name: roomName },
  });

  return response.data;
};

export const getRecordingAccessLink = async (
  recordingId: string,
): Promise<IAccessLinkResponse> => {
  const response = await api.get(`/recordings/${recordingId}/access-link`);
  return response.data;
};
