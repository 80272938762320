import styled from 'styled-components';

interface IContainer {
  padding: number;
  margin: number;
}

export const Container = styled.div<IContainer>`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;
