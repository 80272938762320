import styled from 'styled-components';

export const Content = styled.main`
  backgorund: red;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Select = styled.select`
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 300px;
  border-radius: 8px;
  cursor: pointer;
  pointer-events: all;
  margin-bottom: 50px;
`;

export const SpinWrapper = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecordingsList = styled.ul`
  width: 800px;
  padding: 10px;
  margin: 0 auto;

  > h2 {
    text-align: center;
  }
`;
