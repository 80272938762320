import axios from 'axios';

const token =
  'c35dc0c394aaad99922b2b25140b49b7003d1068f5c15e181fbbdd5ca2c3fad9';

const api = axios.create({
  baseURL: 'https://api.daily.co/v1/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

export default api;
