import React, { useEffect, useState } from 'react';
import {
  formattingDate,
  formattingStartTime,
} from '../../helpers/formatingUnixTimestamp';
import { formatDuration } from '../../helpers/formatSecondsInMinutesAndSeconds';
import { getRecordingAccessLink } from '../../services';
import { IRecording } from '../../types/Room';
import * as S from './styles';

const RecordingItem: React.FC<IRecording> = ({
  id,
  duration,
  room_name: roomName,
  s3key,
  start_ts: startTs,
}) => {
  const [downloadLink, setDownloadLink] = useState<string>('');

  const handleAccessLink = async (): Promise<void> => {
    await getRecordingAccessLink(id)
      .then((response) => setDownloadLink(response.download_link))
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    void handleAccessLink();
  }, [roomName]);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (downloadLink != null && downloadLink?.length > 0) {
      window.open(`${downloadLink}`, 'self');
    }
  };

  return (
    <S.RecordingItem key={id} id={id}>
      <S.Infos>
        <span>duração: {formatDuration(duration)}min</span>
        <span>data: {formattingDate(startTs)}</span>
        <span>gravaçâo iniciada às: {formattingStartTime(startTs)}</span>
      </S.Infos>{' '}
      <S.LinkWrapper>
        <button onClick={(e) => handleButtonClick(e)}>
          <span>Acessar gravação</span>{' '}
        </button>
      </S.LinkWrapper>
    </S.RecordingItem>
  );
};

export default RecordingItem;
